import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  setup: function setup() {
    var dev = useBaseUtils.dev,
      refCode = useBaseUtils.refCode;
    var updatingToken = ref(true); // put this on the $store so authGuard can modify it?
    var successMessage = ref("Thank You For Your Support!");
    return {
      dev: dev,
      refCode: refCode,
      updatingToken: updatingToken,
      successMessage: successMessage
    };
  },
  mounted: function mounted() {
    this.updatingToken = false;
  }
});